<template>
  <card :title="$t('series.info')">
    <form @submit.prevent="create" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('series.created')" />

      <series-form :form="form"></series-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">
            {{ $t('create') }}
          </v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
  import Form from 'vform'
  import SeriesForm from './form'
  import { objectToFormData } from 'object-to-formdata'

  export default {
    scrollToTop: false,
    components: {
      'series-form': SeriesForm
    },
    data: () => ({
      form: new Form({
        thumbnailUrl: '',
        thumbnail: null,
        title: '',
        description: '',
      })
    }),
    methods: {
      async create() {
        const { data } = await this.form.submit('post', '/api/v1/user/me/series', {
          // Transform form data to FormData
          transformRequest: [function (data) {
            return objectToFormData(data)
          }]
        })
        
        this.$store.dispatch('account/updateSeries', { sermon: data.series })
          .then(() => this.$router.push({ name: 'accounts.series.edit', params: {id: data.series.id }}))
        
      }
    }
  }
</script>
